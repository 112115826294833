/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import Vimeo from '@u-wave/react-vimeo';
import React from 'react';
import TwoColumnCta from '../TwoColumnCta';

export default function VideoCta({
  videoId,
  callout,
  headline,
  copy,
  children,
  mediaLeft,
}) {
  return (
    <TwoColumnCta
      callout={callout}
      headline={headline}
      copy={copy}
      children={children}
      mediaLeft={mediaLeft}
      Media={() => (
        <>
          <span className="sr-only">Watch our video to learn more</span>
          <Vimeo responsive video={videoId} />
        </>
      )}
    />
  );
}
