import React from 'react';
import clsx from 'clsx';

const TwoColumnCta = ({
  callout,
  headline,
  copy,
  children,
  Media,
  mediaLeft = false,
}) => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="relative ">
        <main className="mx-auto max-w-7xl px-4 sm:px-6">
          <div className="lg:grid lg:grid-cols-12 items-center lg:gap-8">
            <div
              className={clsx(
                { 'order-2': mediaLeft },
                'sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left'
              )}
            >
              <h1>
                <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                  {callout}
                </span>
                <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl">
                  <span className="block text-gray-900">{headline}</span>
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg">
                {copy}
              </p>
              <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                {children}
              </div>
            </div>
            <div className="lg:col-span-6 lg:flex items-center ">
              <div className="w-full aspect-video my-auto rounded-lg shadow-lg">
                <div className=" bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  {<Media />}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TwoColumnCta;
